<template>
  <div>
    <el-dialog :title="'预览(' + text + ')'"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               v-dialogDrag
               :append-to-body="true"
               width="41.67%"
               :before-close="handleClose">
      <!-- {{url}} -->
      <p style="text-align:center;margin:10px 5px">请注意：ppt,doc和pptx格式文件不能在线预览</p>
      <div class="ifreme_warp">
        <el-image v-if="info.type == 'image'"
                  :src="info.url"
                  style="max-width: 100%; max-height: 100%;border-radius:5px"
                  :preview-src-list="[info.url]"
                  alt="" />
        <video controls
               v-else-if="info.type == 'video'"
               :src="info.url"
               alt="" />
        <audio controls
               v-else-if="info.type == 'audio'">
          <source :src="info.url"
                  type="audio/ogg">
          <source :src="info.url"
                  type="audio/mpeg">
          您的浏览器不支持 audio 元素。
        </audio>
        <div class="download"
             v-else>
          <div>
            <img src="@/assets/icon-ppt.png"
                 alt="">
          </div>
          ppt,doc和pptx格式文件已自动下载
        </div>
      </div>
      <span slot="footer"
            v-if="$parent.form.status == 1"
            class="dialog-footer">
        <el-button type="primary"
                   @click="pass(2)">通过</el-button>
        <el-button @click="nopass"
                   type="danger">不通过</el-button>
      </span>
      <el-dialog height="200px"
                 title="原因"
                 :visible.sync="innerVisible"
                 append-to-body>
        <el-form :model="form"
                 label-width="60px"
                 ref="formRef"
                 :rules="rules">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item prop="reason"
                            label="原因">
                <el-input clearable
                          placeholder="请输入原因"
                          style="width:100%"
                          type="textarea"
                          :rows="4"
                          v-model="form.reason" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="text-align:center">
          <el-button type="primary"
                     @click="submit">确定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      innerVisible: false,
      url: '',
      form: {},
      info: {},
      rules: {
        reason: [
          { required: true, message: '请输入原因', trigger: 'blur' },
        ]
      },
      text: ''
    }
  },
  // watch: {
  //   url () {
  //     // this.url = 
  //     console.log('url', this.url.replace(/\\/g, ''));
  //     setTimeout(() => {
  //       console.log(11, document.getElementsByTagName('iframe'));
  //     }, 1000);
  //     this.url = this.url.replace(/\\/g, '')
  //   }
  // },
  mounted () {

  },
  methods: {
    setIframe () {
      if (this.info.type != 'image' && this.info.type != 'video' && this.info.type != 'audio') {
        window.open(this.info.url)
      }
    },
    handleClose () {
      this.innerVisible = false
      this.dialogVisible = false
    },
    pass (number) {
      this.form.status = number
      console.log('this.form', this.form)
      this.$http({
        url: '/api/v1/course/audit',
        method: 'post',
        data: this.form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功',
          type: 'success'
        });
        this.handleClose()
        this.$parent.search()
      })
    },
    nopass () {
      this.form.reason = ''
      this.innerVisible = true
      this.$nextTick(() => {
        let dialog_body = document.getElementsByClassName('el-dialog__body')
        dialog_body[1].style.height = '250px'
      })
    },
    submit () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.pass(3)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.ifreme_warp {
  overflow: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  width: 83.33%;
  height: 80.25%;
  margin: 0 auto;
  video {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #e5e5e5;
  }
  audio {
    // width: 960px;
    // height: 540px;
  }
}
::v-deep .el-dialog__body {
  height: 400px;
}
.download {
  div {
    text-align: center;
    margin-bottom: 30px;
  }
  font-size: 18px;
  font-weight: 500;
  color: black;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-dialog__body {
  padding-bottom: 0;
}
::v-deep .el-button--medium {
  width: 90px;
  height: 36px;
  border-radius: 28px;
}
</style>